import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/layout';

const NotFoundPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>404: Not found</title>
    </Helmet>
    <h1>NOT FOUND</h1>
  </Layout>
);

export default NotFoundPage;
